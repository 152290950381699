import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, ReactNode, useContext } from "react";
import { CredentialResponse } from "@react-oauth/google";
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { redirect } from 'react-router-dom';
import { APIClient } from '../lib/api_client';
import Cookies from "js-cookie";

interface AuthContextType {
  handleSocialAuthTokenExchange: (response: CredentialResponse) => Promise<void>;
  logOut: () => void;
  getAuthTokenClaims: () => JwtPayload;
  refreshAccessToken: any;
  isAuthenticated: boolean;
  isLoading: boolean;
}

export interface GoogleJwtPayload {
  // Standard  JWT claims
  iss: string;
  sub: string;
  aud: string[] | string;
  exp: number;
  nbf: number;
  iat: number;
  jti: string;
  // Non-standard JWT claims
  azp?: string;
  hd?: string;
  email?: string;
  email_verified?: boolean;
  name?: string;
  picture?: string;
  given_name?: string;
  family_name?: string;
  locale?: string;
}
export interface TokenVerificationResponse {
  valid: boolean;
  idinfo?: GoogleJwtPayload;
}

export interface AuthTokenResponse {
  access: string;
  refresh: string;
  valid: boolean;
  idinfo: GoogleJwtPayload;
}

const AuthContext = createContext<AuthContextType>({
  handleSocialAuthTokenExchange: async () => { },
  logOut: () => { },
  getAuthTokenClaims: () => { return {} },
  refreshAccessToken: () => { return {} },
  isAuthenticated: false,
  isLoading: false
});

/**
 * React component that provides authentication context for the application.
 * It manages authentication state, token handling, and related operations.
 */
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();

  const getAuthTokenClaims = (): JwtPayload => {
    const token = Cookies.get("mindara_at");
    if (token) {
      return jwtDecode(token)
    }
    return {}
  }

  const exchangeSocialToken = async (socialCredential: string): Promise<AuthTokenResponse> => {
    const response = await APIClient.post<AuthTokenResponse>('/token', { socialCredential: socialCredential });
    return response.data;
  };

  const refreshAccessToken = async (): Promise<AuthTokenResponse> => {
    const refreshToken = Cookies.get("mindara_rt");
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    const response = await APIClient.post<AuthTokenResponse>('/token/refresh/', { refresh: refreshToken });
    const { access, refresh } = response.data;

    Cookies.set("mindara_at", access, { sameSite: "lax", expires: 1 });
    Cookies.set("mindara_rt", refresh, { sameSite: "lax", expires: 7 });

    return response.data;
  };

  const verifyToken = async (): Promise<boolean> => {
    try {
      let token = Cookies.get("mindara_at");
      if (!token) return false;

      const decodedToken = jwtDecode<GoogleJwtPayload | undefined>(token);
      if (decodedToken === undefined) {
        console.debug("No authentication token exists");
        return false;
      }

      // If token is expired but we have a refresh token, try to refresh
      if (decodedToken.exp < (Date.now() / 1000)) {
        console.debug("Access token expired, attempting refresh");
        try {
          await refreshAccessToken();
          token = Cookies.get("mindara_at");
          if (!token) return false;
        } catch (refreshError) {
          console.debug("Token refresh failed");
          return false;
        }
      }

      const response = await APIClient.post('/token/verify/', { token });
      return response.status === 200;
    } catch {
      return false;
    }
  };

  const tokenVerificationQuery = useQuery({
    queryKey: ['tokenVerify'],
    queryFn: verifyToken,
    staleTime: 1 * 60 * 1000, // 1 minute
    retry: false
  });

  const handleSocialAuthTokenExchange = async (response: CredentialResponse) => {
    if (!response.credential) {
      throw new Error("No credential received from Google");
    }

    try {
      const tokens = await exchangeSocialToken(response.credential);
      Cookies.set("social_at", response.credential, { sameSite: "lax", expires: 7 });
      Cookies.set("mindara_at", tokens.access, { sameSite: "lax", expires: 1 });
      Cookies.set("mindara_rt", tokens.refresh, { sameSite: "lax", expires: 7 });

      // Invalidate token verification query to force a refresh
      await queryClient.invalidateQueries({ queryKey: ['tokenVerify'] });
    } catch (error) {
      console.error('Token exchange failed:', error);
      throw error;
    }
  };

  const logOut = () => {
    Cookies.remove("social_at");
    Cookies.remove("mindara_at");
    Cookies.remove("mindara_rt");
    queryClient.invalidateQueries({ queryKey: ['tokenVerify'] });
    redirect("/login")
  };

  const value = {
    handleSocialAuthTokenExchange,
    logOut,
    getAuthTokenClaims,
    refreshAccessToken,
    isAuthenticated: tokenVerificationQuery.data ?? false,
    isLoading: tokenVerificationQuery.isLoading ?? false
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook for accessing authentication context
 */
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthContext, AuthProvider, useAuth };
