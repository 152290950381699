import axios from "axios";
import Cookies from "js-cookie";
import { useAuth } from "../hooks/auth_provider";

let isRefreshing = false;
let failedQueue: any[] = [];

const APIEndpoint: string = process.env.NODE_ENV === 'production' ? `https://${process.env.REACT_APP_HOST}/api` : 'http://localhost:3000/api'
const APIClient = axios.create({ baseURL: APIEndpoint, withCredentials: true });

/**
 * Processes the failed queue by resolving or rejecting promises based on the error state.
 */
const processQueue = (error: any = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

APIClient.interceptors.request.use((config) => {
  const token = Cookies.get("mindara_at");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

APIClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const auth = useAuth();
    const originalRequest = error.config;

    if ([401, 403].includes(error.response?.status) && !originalRequest._retry) {
      // If we're already refreshing the token, queue this request for afterwards
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return APIClient(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const { access } = await auth.refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${access}`;

        processQueue();
        isRefreshing = false;

        return APIClient(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError as Error);
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export { APIClient, APIEndpoint }
