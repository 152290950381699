import React from 'react';
import { Marker } from '@react-google-maps/api';
import { CustomMapMarker } from './types';

interface MapMarkerProps {
  marker: CustomMapMarker;
  isUser?: boolean;
  onClick?: () => void;
}

const MarkerIcon = (isUser: boolean) => {
  const svg = `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="${isUser ? '#00FFFF' : 'rgba(0, 255, 255, 0.2)'}" />
        <circle cx="12" cy="12" r="6" fill="#00FFFF" />
      </svg>
  `;
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
}

const MapMarker: React.FC<MapMarkerProps> = ({ marker, isUser, onClick }) => {
  console.log("Creating marker for:", marker.lat, marker.lng)
  return (
    <Marker
      position={{ lat: marker.lat, lng: marker.lng }}
      onClick={onClick}
      title={marker.name}
      icon={{
        url: MarkerIcon(isUser || false),
        scaledSize: new google.maps.Size(24, 24),
        anchor: new google.maps.Point(12, 12),
      }}
    />
  );
};

export default React.memo(MapMarker);
