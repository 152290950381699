import React, { createContext, useContext, useState, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { APIClient } from '../lib/api_client';
import { Activity, Plan } from '../types/interfaces';

interface PlanContextType {
  activities: Activity[];
  bookResyReservation: (venueId: string, time: string, partySize: number) => Promise<void>;
  deletePlan: (planId: string) => Promise<void>;
  fetchUserPlans: () => Promise<Plan[]>;
  findResyVenue: (query: string) => Promise<any>;
  isAtMaxCapacity: boolean;
  isLoading: boolean;
  savePlan: (plan: Plan) => Promise<void>;
  selectedPlan: Plan | null;
  setActivities: (activities: Activity[]) => void;
  setSelectedPlan: (plan: Plan | null) => void;
  userPlans: Plan[];
}

const PlanContext = createContext<PlanContextType | undefined>(undefined);

const savePlan = async (plan: Plan): Promise<Plan> => {
  if (plan.id) {
    const response = await APIClient.patch(`/plans/${plan.id}/`, plan);
    return response.data;
  } else {
    const response = await APIClient.post('/plans/', plan);
    return response.data;
  }
};

export const PlanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [isAtMaxCapacity, setIsAtMaxCapacity] = useState(false);
  const [userPlans, setUserPlans] = useState<Plan[]>([]);


  const fetchUserPlans = async (): Promise<Plan[]> => {
    const response = await APIClient.get('/plans/');
    setUserPlans(response.data.results);
    return response.data.results;
  };
  
  
  const userPlansQuery = useQuery<Plan[]>({
    queryKey: ['userPlans'],
    queryFn: fetchUserPlans,
    staleTime: 1 * 60 * 1000, // 1 minute
  });

  const { mutateAsync: savePlanMutation } = useMutation({
    mutationFn: savePlan,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPlans'] });
    },
    onError: (error) => {
      console.error('Failed to save plan:', error);
    }
  });

  const handleSavePlan = useCallback(async (plan: Plan) => {
    try {
      await savePlanMutation(plan);
    } catch (error) {
      console.error('Error saving plan:', error);
    }
  }, [savePlanMutation]);

  const deletePlan = useCallback(async (planId: string) => {
    try {
      await APIClient.delete(`/plans/${planId}/`);
      queryClient.invalidateQueries({ queryKey: ['userPlans'] });
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  }, [queryClient]);

  const bookResyReservation = useCallback(async (venueId: string, time: string, partySize: number) => {
    try {
      await APIClient.post('/resy/book', { venue_id: venueId, time, party_size: partySize });
    } catch (error) {
      console.error('Error booking reservation:', error);
    }
  }, []);

  const findResyVenue = useCallback(async (query: string) => {
    try {
      const response = await APIClient.get(`/resy/search?query=${encodeURIComponent(query)}`);
      return response.data;
    } catch (error) {
      console.error('Error finding venue:', error);
      return [];
    }
  }, []);

  return (
    <PlanContext.Provider
      value={{
        activities,
        bookResyReservation,
        deletePlan,
        fetchUserPlans,
        findResyVenue,
        isAtMaxCapacity,
        isLoading: userPlansQuery.isLoading ?? false,
        savePlan: handleSavePlan,
        selectedPlan,
        setActivities,
        setSelectedPlan,
        userPlans,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};