import React from 'react';
import { motion } from 'motion/react';
import './FilterPanel.scss';
import { ActivityEnum, AmbianceEnum } from './types';

interface FilterPanelProps {
  filters: {
    activityType: string[];
    priceRange: number[];
    ambiance: string[];
    distance: number;
  };
  onChange: (filters: any) => void;
  isOpen: boolean;
  onToggle: () => void;
}

export const FilterPanel: React.FC<FilterPanelProps> = ({ filters, onChange, isOpen, onToggle }) => {
  const toggleFilter = (type: string, value: string) => {
    const currentFilters = [...(filters[type as keyof typeof filters] as string[])];
    const index = currentFilters.indexOf(value);

    if (index === -1) {
      currentFilters.push(value);
    } else {
      currentFilters.splice(index, 1);
    }

    onChange({
      ...filters,
      [type]: currentFilters,
    });
  };

  return (
    <>
      {!isOpen && (
        <button className="filter-button" onClick={onToggle}>
          Filters
        </button>
      )}

      {isOpen && (
        <motion.div
          className="filter-container"
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 300 }}
        >
          <button className="close-button" onClick={onToggle}>
            ✕
          </button>

          <div className="filter-section">
            <h3>Activity Type</h3>
            <div className="chip-container">
              {Object.values(ActivityEnum).map(type => (
                <button
                  key={type}
                  className={`chip ${filters.activityType.includes(type) ? 'active' : ''}`}
                  onClick={() => toggleFilter('activityType', type)}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <div className="filter-section">
            <h3>Price Range</h3>
            <input
              className="range-slider"
              type="range"
              min={0}
              max={4}
              value={filters.priceRange[1]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange({
                ...filters,
                priceRange: [0, parseInt(e.target.value)],
              })}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>$</span>
              <span>{'$'.repeat(filters.priceRange[1])}</span>
            </div>
          </div>

          <div className="filter-section">
            <h3>Ambiance</h3>
            <div className="chip-container">
              {Object.values(AmbianceEnum).map(type => (
                <button
                  key={type}
                  className={`chip ${filters.ambiance.includes(type) ? 'active' : ''}`}
                  onClick={() => toggleFilter('ambiance', type)}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <div className="filter-section">
            <h3>Distance (km)</h3>
            <input
              className="range-slider"
              type="range"
              min={1}
              max={10}
              value={filters.distance / 1000}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange({
                ...filters,
                distance: parseInt(e.target.value) * 1000,
              })}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>1km</span>
              <span>{filters.distance / 1000}km</span>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};
