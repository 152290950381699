import React, { useState, useRef } from 'react';
import { Box, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { Favorite, BookmarkBorder, Edit, Send } from '@mui/icons-material';
import './Inspiration.scss';


interface ReelCardProps {
  id: number;
  images: string[];
}

const SWIPE_THRESHOLD = 65;

/**
 * Renders a ReelCard component with swipeable image carousel and interactive buttons.
 */
const ReelCard: React.FC<ReelCardProps> = ({ id, images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef<number>(0);
  const touchStartY = useRef<number>(0);
  const isScrollingRef = useRef<boolean | null>(null);
  const hasSwipedRef = useRef<boolean>(false);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
    isScrollingRef.current = null;
    hasSwipedRef.current = false;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!containerRef.current || hasSwipedRef.current) return;

    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;
    const diffX = touchStartX.current - currentX;
    const diffY = touchStartY.current - currentY;

    // Determine scroll direction on initial movement
    if (isScrollingRef.current === null) {
      isScrollingRef.current = Math.abs(diffY) > Math.abs(diffX);

      // Allow vertical scrolling
      if (isScrollingRef.current) return;
    }

    // Handle horizontal swipe
    if (!isScrollingRef.current) {
      e.preventDefault(); // Prevent vertical scroll during horizontal swipe



      if (Math.abs(diffX) > SWIPE_THRESHOLD && !hasSwipedRef.current) {
        if (diffX > 0 && imageIndex < images.length - 1) {
          setImageIndex(prev => prev + 1);
          hasSwipedRef.current = true; // Prevent multiple swipes
        } else if (diffX < 0 && imageIndex > 0) {
          setImageIndex(prev => prev - 1);
          hasSwipedRef.current = true;
        }
      }
    }
  };

  const handleTouchEnd = () => {
    isScrollingRef.current = null;
    hasSwipedRef.current = false;
  };

  // Handle mouse drag for desktop
  const isDraggingRef = useRef<boolean>(false);
  const dragStartX = useRef<number>(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    isDraggingRef.current = true;
    dragStartX.current = e.clientX;
    hasSwipedRef.current = false;

    // Prevent text selection during drag
    e.preventDefault();
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDraggingRef.current || hasSwipedRef.current) return;

    const diffX = dragStartX.current - e.clientX;

    if (Math.abs(diffX) > SWIPE_THRESHOLD) {
      if (diffX > 0 && imageIndex < images.length - 1) {
        setImageIndex(prev => prev + 1);
        hasSwipedRef.current = true;
      } else if (diffX < 0 && imageIndex > 0) {
        setImageIndex(prev => prev - 1);
        hasSwipedRef.current = true;
      }
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    hasSwipedRef.current = false;
  };

  const handleMouseLeave = () => {
    isDraggingRef.current = false;
    hasSwipedRef.current = false;
  };

  return (
    <div className="reel-card-wrapper">
      <Card className="reel-card">
        <div
          ref={containerRef}
          className="reel-images-container"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <div className="reel-images-track" style={{ transform: `translateX(-${imageIndex * 100}%)` }}>
            {images.map((image, idx) => (
              <CardMedia
                key={idx}
                component="img"
                className="reel-image"
                image={image}
                alt={`Slide ${idx + 1}`}
              />
            ))}
          </div>

          <div className="carousel-dots">
            {images.map((_, idx) => (<div key={idx} className={`dot ${idx === imageIndex ? 'active' : ''}`} />))}
          </div>

          <div className="content-overlay">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={1}>
                <IconButton className="action-button"><Favorite /></IconButton>
                <IconButton className="action-button"><Edit /></IconButton>
                <IconButton className="action-button"> <Send /></IconButton>
              </Box>
              <IconButton className="action-button"><BookmarkBorder /></IconButton>
            </Box>

            <div className="reel-content">
              <Typography variant="subtitle1" className="username">
                [AI-generated Plan name]
              </Typography>
              <Typography variant="body2" className="caption">
                This is features a [beginning], [middle], and [end] over [x] hours.<br />
                2 people, $36 ea.
              </Typography>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

const Inspiration: React.FC = () => {
  const reels = Array.from({ length: 5 }, (_, i) => ({
    id: i + 1,
    images: [
      `https://placehold.co/400x600?text=Reel${i + 1}-1`,
      `https://placehold.co/400x600?text=Reel${i + 1}-2`,
      `https://placehold.co/400x600?text=Reel${i + 1}-3`
    ]
  }));

  return (
    <div className="inspiration-container">
      <div className="reels-scroll-container">
        {reels.map((reel) => (
          <ReelCard
            key={reel.id}
            id={reel.id}
            images={reel.images}
          />
        ))}
      </div>
    </div>
  );
};

export default Inspiration;
