import { useQuery } from '@tanstack/react-query';
import { APIClient, APIEndpoint } from '../lib/api_client';

/**
 * Custom React Query hook for checking Resy connection status
 */
export const useResyConnection = () => {
  return useQuery({
    queryKey: ['resyConnection'],
    queryFn: async () => {
      try {
        const response = await APIClient.get(`${APIEndpoint}/services/resy/status/`);
        return response.data.connected;
      } catch (error) {
        return false;
      }
    },
    staleTime: 30000,
  });
};
