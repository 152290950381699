import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Box
} from '@mui/material';
import { usePlan } from '../../hooks/plan_provider';
import type { Plan } from '../../hooks/plan_provider';
import { motion, AnimatePresence } from 'framer-motion';
import { CustomMapMarker } from '../Map/types';
import { useQueryClient } from '@tanstack/react-query';
import { APIClient, APIEndpoint } from '../../lib/api_client';

/**
 * React functional component that renders a dashboard displaying user plans.
 * It provides functionality to view, edit, delete plans, and book restaurant reservations.
 */
const Dashboard: React.FC = () => {
  const { userPlans, loadPlanForEdit, deletePlan, bookResyReservation, findResyVenue } = usePlan();
  const queryClient = useQueryClient();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<Plan | null>(null);
  const [resyDialogOpen, setResyDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [resyBookingDetails, setResyBookingDetails] = useState({
    partySize: 2,
    date: new Date(),
    time: '19:00'
  });

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoading(true);
        setPlans(userPlans);
        setError(null);
      } catch (err) {
        setError('Failed to load plans');
        console.error('Error fetching plans:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [userPlans]);

  const handleEdit = useCallback((plan: Plan) => {
    if (plan.id) {
      loadPlanForEdit(plan.id);
    }
  }, [loadPlanForEdit]);

  const handleDeleteClick = useCallback((plan: Plan) => {
    setPlanToDelete(plan);
    setDeleteDialogOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    if (!planToDelete?.id) return;

    try {
      await deletePlan(planToDelete.id);
      setPlans(plans.filter(p => p.id !== planToDelete.id));
      setDeleteDialogOpen(false);
      setPlanToDelete(null);
    } catch (err) {
      setError('Failed to delete plan. Please try again.');
    }
  }, [deletePlan, planToDelete, plans]);

  const handleResyBook = useCallback((plan: Plan) => {
    setSelectedPlan(plan);
    setResyDialogOpen(true);
  }, []);

  const handleResyBookingSubmit = useCallback(async () => {
    if (!selectedPlan?.activities?.[0]) return;

    try {
      const restaurant = selectedPlan.activities[0];
      const venues = await findResyVenue(restaurant.name);

      if (venues && venues.length > 0) {
        const venue = venues[0];
        await bookResyReservation(
          venue.id,
          resyBookingDetails.time,
          resyBookingDetails.partySize
        );
      }
      setResyDialogOpen(false);
    } catch (error) {
      console.error('Failed to book reservation:', error);
      setError('Failed to book reservation. Please try again.');
    }
  }, [selectedPlan, findResyVenue, bookResyReservation, resyBookingDetails]);

  const handlePlanUpdate = useCallback(async (updatedStops: CustomMapMarker[]) => {
    if (selectedPlan?.id) {
      try {
        await APIClient.put(`${APIEndpoint}/plans/${selectedPlan.id}`, { stops: updatedStops });
        await queryClient.invalidateQueries({ queryKey: ['userPlans'] });
      } catch (error) {
        console.error('Error updating plan:', error);
        setError('Failed to update plan. Please try again.');
      }
    }
  }, [selectedPlan, queryClient]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Grid container spacing={3} padding={3}>
      <AnimatePresence>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {plan.name}
                  </Typography>
                  <Box className="plan-activities">
                    {plan.activities.map((activity: CustomMapMarker, index: number) => (
                      <Typography key={activity.id} variant="body2">
                        {index + 1}. {activity.name}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
                <CardActions className="plan-actions">
                  <Button size="small" onClick={() => handleEdit(plan)}>
                    Edit
                  </Button>
                  <Button size="small" color="secondary" onClick={() => handleDeleteClick(plan)}>
                    Delete
                  </Button>
                  <Button size="small" color="primary" onClick={() => handleResyBook(plan)}>
                    Book Reservation
                  </Button>
                </CardActions>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </AnimatePresence>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Plan</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete "{planToDelete?.name}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resyDialogOpen} onClose={() => setResyDialogOpen(false)}>
        <DialogTitle>Book Restaurant Reservation</DialogTitle>
        <DialogContent>
          <TextField
            label="Party Size"
            type="number"
            value={resyBookingDetails.partySize}
            onChange={(e) => setResyBookingDetails(prev => ({ ...prev, partySize: parseInt(e.target.value) }))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date"
            type="date"
            value={resyBookingDetails.date.toISOString().split('T')[0]}
            onChange={(e) => setResyBookingDetails(prev => ({ ...prev, date: new Date(e.target.value) }))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Time"
            type="time"
            value={resyBookingDetails.time}
            onChange={(e) => setResyBookingDetails(prev => ({ ...prev, time: e.target.value }))}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResyDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleResyBookingSubmit} variant="contained" color="primary">
            Book Now
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Dashboard;
