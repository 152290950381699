/**
 * Validates if the given string is a valid email address.
 */
export const isValidEmail = (email: string) => {
  const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
  return emailRegex.test(email);
}

/**
 * Validates if a password meets the specified criteria.
 */
export const isValidPassword = (password: string) => {
  const passwordRegex = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
  return passwordRegex.test(password);
}

/**
 * Validates if the given string is a valid phone number.
 */
export const isValidPhone = (phone: string): boolean => {
  // The regex below Matches:
  //   15109437012
  //   5109437012
  //   (510) 943-7012
  //   (510)943-7012
  //   +15109437012       NOTE: This is the format that Resy anticipates
  //   +1 510 943 7012
  //   +12 (510) 943-7012
  // etc...
  const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return phoneRegex.test(phone);
}

/**
 * Validates if a given string is a valid SMS code.
 */
export const isValidSMSCode = (code: string) => {
  console.log(code)
  const codeRegex = /^\d{3}\-?\d{3}$/
  return codeRegex.test(code);
}

/**
 * Normalizes a phone number by removing formatting characters and adding a default country code if necessary.
 */
export const normalizePhone = (phone: string, defaultCountryCode: string = "+1"): string | null => {
  const cleanedNumber = phone.replace(/[\s\-\(\).]/g, "");

  if (!isValidPhone(cleanedNumber)) return null;
  if (phone.startsWith("+") && phone.length >= 11) return phone;

  return `${defaultCountryCode}${cleanedNumber}`;
}
