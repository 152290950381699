import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useGeolocation } from '../../hooks/geolocation_context';
import { CustomMapMarker, NavigationError, NavigationStep } from './types';
import { CircularProgress, Snackbar, Alert, LinearProgress } from '@mui/material';
import { Place } from '@mui/icons-material';
import './RealTimeNavigation.scss';

interface RealTimeNavigationProps {
  currentPlan: {
    stops: CustomMapMarker[];
    currentStopIndex: number;
  };
  onRouteDeviation: () => void;
  onStopReached: () => void;
}

const MAX_RECONNECTION_ATTEMPTS = 3;
const RECONNECTION_DELAY = 2000;
const ARRIVAL_THRESHOLD_METERS = 20;

const RealTimeNavigation: React.FC<RealTimeNavigationProps> = ({
  currentPlan,
  onRouteDeviation,
  onStopReached
}) => {
  const { position } = useGeolocation();
  const [currentStep, setCurrentStep] = useState<NavigationStep | null>(null);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<NavigationError | null>(null);
  const reconnectionAttempts = useRef(0);
  const reconnectionTimeout = useRef<NodeJS.Timeout>();
  const [distanceToNextStop, setDistanceToNextStop] = useState<number>(0);
  const lastPosition = useRef<{ lat: number; lng: number } | null>(null);

  const calculateDistance = useCallback((lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6378137; // Earth's radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }, []);

  const connectWebSocket = useCallback(() => {
    try {
      const ws = new WebSocket(process.env.REACT_APP_WS_ENDPOINT || 'ws://localhost:8000/ws/navigation/');

      ws.onopen = () => {
        console.log('Navigation WebSocket Connected');
        setIsLoading(false);
        reconnectionAttempts.current = 0;

        if (position && currentPlan.stops.length > 0) {
          ws.send(JSON.stringify({
            type: 'start_navigation',
            origin: {
              lat: position.lat,
              lng: position.lng
            },
            destination: {
              lat: currentPlan.stops[currentPlan.currentStopIndex].lat,
              lng: currentPlan.stops[currentPlan.currentStopIndex].lng
            },
            waypoints: currentPlan.stops.slice(currentPlan.currentStopIndex + 1).map(stop => ({
              lat: stop.lat,
              lng: stop.lng
            }))
          }));
        }
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          handleNavigationUpdate(data);
        } catch (error) {
          setError({
            message: 'Failed to process navigation update',
            severity: 'warning'
          });
        }
      };

      ws.onerror = () => {
        setError({
          message: 'Navigation connection error',
          severity: 'error'
        });
      };

      ws.onclose = () => {
        handleReconnection();
      };

      setWebsocket(ws);

      return ws;
    } catch (error) {
      setError({
        message: 'Failed to establish navigation connection',
        severity: 'error'
      });
      return null;
    }
  }, [position, currentPlan]);

  const handleReconnection = useCallback(() => {
    if (reconnectionAttempts.current < MAX_RECONNECTION_ATTEMPTS) {
      reconnectionAttempts.current += 1;
      setError({
        message: `Reconnecting... Attempt ${reconnectionAttempts.current}/${MAX_RECONNECTION_ATTEMPTS}`,
        severity: 'info'
      });

      reconnectionTimeout.current = setTimeout(() => {
        connectWebSocket();
      }, RECONNECTION_DELAY * Math.pow(2, reconnectionAttempts.current - 1));
    } else {
      setError({
        message: 'Failed to reconnect to navigation service',
        severity: 'error'
      });
    }
  }, [connectWebSocket, setError]);

  useEffect(() => {
    const ws = connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectionTimeout.current) {
        clearTimeout(reconnectionTimeout.current);
      }
    };
  }, [connectWebSocket]);

  const handleNavigationUpdate = useCallback((data: any) => {
    try {
      if (data.type === 'navigation_update') {
        setCurrentStep({
          instruction: data.instruction,
          distance: data.distance,
          duration: data.duration
        });
        setError(null);
      } else if (data.type === 'route_deviation') {
        onRouteDeviation();
      } else if (data.type === 'error') {
        setError({
          message: data.message,
          severity: 'error'
        });
      }
    } catch (error) {
      setError({
        message: 'Failed to process navigation data',
        severity: 'error'
      });
    }
  }, [onRouteDeviation, setCurrentStep, setError]);

  useEffect(() => {
    if (websocket && position && websocket.readyState === WebSocket.OPEN) {
      try {
        websocket.send(JSON.stringify({
          type: 'position_update',
          position: {
            lat: position.lat,
            lng: position.lng
          },
          plan_id: currentPlan.stops[0].id
        }));
      } catch (error) {
        setError({
          message: 'Failed to send position update',
          severity: 'warning'
        });
      }
    }
  }, [position, websocket, currentPlan]);

  useEffect(() => {
    if (position && currentPlan.stops.length > 0) {
      const currentStop = currentPlan.stops[currentPlan.currentStopIndex];
      const distance = calculateDistance(
        position.lat,
        position.lng,
        currentStop.lat,
        currentStop.lng
      );

      setDistanceToNextStop(distance);

      if (distance <= ARRIVAL_THRESHOLD_METERS) {
        onStopReached();
      }

      lastPosition.current = position;
    }
  }, [position, currentPlan, calculateDistance, onStopReached]);

  const handleWebSocketMessage = useCallback((event: MessageEvent) => {
    const data = JSON.parse(event.data);
    if (data.type === 'navigation_update') {
      handleNavigationUpdate(data);
    } else if (data.type === 'reconnection') {
      handleReconnection();
    }
  }, [handleNavigationUpdate, handleReconnection]);

  return (
    <>
      <div className="progress-container">
        <div className="stop-info">
          <Place className="stop-icon" />
          <div>
            {currentPlan.stops[currentPlan.currentStopIndex]?.name || 'Next Stop'}
            <div style={{ fontSize: '12px', opacity: 0.7 }}>
              {Math.round(distanceToNextStop)} meters away
            </div>
          </div>
        </div>
        <LinearProgress
          className="stop-progress"
          variant="determinate"
          value={Math.max(0, Math.min(100, 100 - (distanceToNextStop / 1000)))}
        />
      </div>

      {currentStep && (
        <div className="navigation-overlay">
          <div className="instruction">{currentStep.instruction}</div>
          <div className="details">
            {currentStep.distance} • {currentStep.duration}
          </div>
        </div>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={error?.severity === 'info' ? 2000 : 6000}
        onClose={() => setError(null)}
      >
        <Alert severity={error?.severity || 'error'} sx={{ width: '100%' }}>
          {error?.message}
        </Alert>
      </Snackbar>

      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress sx={{ color: '#00FFFF' }} />
        </div>
      )}
    </>
  );
};

export default RealTimeNavigation; 