import React, { BaseSyntheticEvent, useContext, useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Header.scss';
import { AuthContext, GoogleJwtPayload } from '../../hooks/auth_provider';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

/**
 * Header component for the application.
 * Renders an AppBar with a logo, user avatar, and a menu for authenticated users.
 */
const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => navigate("/");
  const handleClose = () => setAnchorEl(null);

  const handleMenuClick = (e: BaseSyntheticEvent) => {
    handleClose();
    navigate(`/${e.target.textContent.toLowerCase()}`);
  };

  const fetchAvatarImage = () => {
    try {
      const token = Cookies.get("social_at");
      return token ? jwtDecode<GoogleJwtPayload>(token).picture ?? '/default-avatar.png' : '/default-avatar.png';
    } catch {
      return '/default-avatar.png';
    }
  }

  return (
    auth.isAuthenticated ? <div className="header-container">
      <AppBar position="sticky" className="header">
        <Toolbar>
          <IconButton edge="start" className="header-title" color="inherit" aria-label="menu" onClick={handleIconClick}>
            <Avatar src={'/new-mindara-logo.jpg'} alt="User profile" />
          </IconButton>
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenu}>
            <Avatar className='user-avatar' src={fetchAvatarImage()} alt="User profile" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 72, horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleMenuClick}>Settings</MenuItem>
            <MenuItem onClick={handleMenuClick}>Integrations</MenuItem>
            <MenuItem onClick={() => { handleClose(); auth.logOut() }}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div> : <></>
  );
}

export default Header;