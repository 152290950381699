import React, { useCallback, useRef, useState, useMemo, useEffect, memo, useContext } from 'react';
import { APIEndpoint, APIClient } from '../../lib/api_client';
import { GoogleMap, useLoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';
import { usePlan } from '../../hooks/plan_provider';
import { useQuery } from '@tanstack/react-query';
import { Activity, CustomPlace, Plan } from '../../types/interfaces';
import Path from './Path';
import ActivityDrawer from '../ActivityDrawer/ActivityDrawer';
// import RealTimeNavigation from './RealTimeNavigation';
import { GoogleMapsClient } from '../../lib/maps_client';
import { useGeolocation } from '../../hooks/geolocation_context';
import Cookies from 'js-cookie';
import MapMarker from './MapMarker';
import './Map.scss';
import { Alert, CircularProgress } from '@mui/material';
import { FilterPanel } from './FilterPanel';
import { DEFAULT_LOCATION, GeolocationContext } from '../../hooks/geolocation_provider';
import PlanStepper from '../PlanStepper/PlanStepper';


const DEFAULT_ZOOM = 13;
interface FilterOptions {
  activityTypes: string[];
  priceRange: [number, number];
  ambiance: string[];
  distance: number;
}

interface NavigationState {
  isActive: boolean;
  currentPath: google.maps.LatLng[];
  currentActivityIndex: number;
  remainingActivities: Activity[];
}

const GOOGLE_MAPS_LIBRARIES: ("places")[] = ['places'];
const MAP_STYLES: google.maps.MapTypeStyle[] = [
  { elementType: "geometry", stylers: [{ color: "#011B2D" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#011B2D" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road", elementType: "geometry", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road", elementType: "geometry.stroke", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road", elementType: "labels.text.fill", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#00FFFF" }] },
  { featureType: "water", elementType: "geometry", stylers: [{ color: "#001B2D" }] },
  { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#00FFFF" }] },
  { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] }
];


const getIconUrl = (marker: CustomPlace): string => {
  // We could use types, but they need to fit within those types allowable in this big list:
  // https://developers.google.com/maps/documentation/places/web-service/supported_types
  // Note that types are not mutually exclusive. A place may have both food and bar types.
  //
  // We'll do price range for now: Free, Inexpensive, Moderate, Expensive, Very Expensive
  // let logo_path = `/logo-red.png`;
  // let colors = ['green', 'yellow', 'magenta', 'purple', 'red']
  // if (marker.price_level) logo_path = `/logo-${colors[marker.price_level]}.png`;

  return '/marker.png'
}

const MemoizedMarker = memo(({ place, onClick }: { place: CustomPlace; onClick: () => void }) => (
  <Marker
    key={place.place_id}
    position={{ lat: place.lat, lng: place.lng }}
    onClick={onClick}
    title={place.name}
    icon={{ url: getIconUrl(place), scaledSize: new google.maps.Size(24, 24) }}
  />
));

const Map: React.FC = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const { activities, setActivities, selectedPlan, setSelectedPlan } = usePlan();
  const mapRef = useRef<google.maps.Map | null>(null);
  const [center, setCenter] = useState(DEFAULT_LOCATION);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [customPlaces, setCustomPlaces] = useState<CustomPlace[]>([]);
  const [pathMakers, setPathMarkers] = useState<any>([]);
  const [isLoadingMarkers, setIsLoadingMarkers] = useState(false);
  const [currentActivity, setCurrentActivity] = useState<Activity | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<FilterOptions>({
    activityTypes: [],
    priceRange: [0, 4],
    ambiance: [],
    distance: 5000
  });
  const [navigationState, setNavigationState] = useState<NavigationState>({
    isActive: false,
    currentPath: [],
    currentActivityIndex: 0,
    remainingActivities: []
  });
  const { userGeolocation, error } = useContext(GeolocationContext);
  const mapsClient = useMemo(() => new GoogleMapsClient(), []);

  const activityMapping = useMemo(() => ({
    food: ['restaurant', 'bar', 'cafe'],
    entertainment: ['entertainment'],
    culture: ['attraction'],
    shopping: ['shopping'],
    outdoors: ['attraction']
  }), []);

  useEffect(() => {
    if (isLoaded) {
      try {
        mapsClient.initialize();
      } catch (error) {
        console.error('Failed to initialize Google Maps client:', error);
      }
    }
  }, [isLoaded, mapsClient]);

  // useEffect(() => {
  //   if (position) {
  //     const userLocation = {
  //       lat: position.lat,
  //       lng: position.lng,
  //     };
  //     setCenter(userLocation);
  //     if (mapRef.current) {
  //       mapRef.current.panTo(userLocation);
  //       if (!sessionStorage.getItem('initial_location_set')) {
  //         mapRef.current.setZoom(15);
  //         sessionStorage.setItem('initial_location_set', 'true');
  //       }
  //     }

  //     if (activities.length > 0) {
  //       const newPaths = activities.map(activity => ({
  //         path_id: `path-${activity.place_id}`,
  //         points: [
  //           { lat: userLocation.lat, lng: userLocation.lng, place_id: 'user', name: 'Your Location', isUser: true },
  //           activity
  //         ]
  //       }));
  //       setPathMarkers(newPaths);
  //     }
  //   }
  // }, [position, activities]);

  const handleFilterSelect = useCallback((filter: string) => {
    setFilters(prev => ({
      ...prev,
      activityTypes: activityMapping[filter as keyof typeof activityMapping] || []
    }));
  }, [activityMapping]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    // Only add event listeners if map and div exist
    if (map && map.getDiv()) {
      const mapDiv = map.getDiv();
      mapDiv.addEventListener('touchstart', () => { }, { passive: true });
      mapDiv.addEventListener('touchmove', () => { }, { passive: true });
    }
  }, []);

  const paths = useMemo(() => {
    return activities.map(activity => ({
      points: [
        { lat: center.lat, lng: center.lng, place_id: 'user', name: 'Your Location', isUser: true },
        activity
      ]
    }));
  }, [customPlaces, center]);

  const fetchMarkers = async (): Promise<CustomPlace[]> => {
    const response = await APIClient.get(`${APIEndpoint}/markers/`, {
      headers: { Accept: 'application/json' },
      params: userGeolocation || DEFAULT_LOCATION
    });
    return response.data || [];
  };

  const fetchMarkersQuery = useQuery<CustomPlace[]>({
    queryKey: ['fetchMarkers'],
    queryFn: fetchMarkers,
    // staleTime: 15000, // 15 seconds
    enabled: !!Cookies.get("mindara_at"),
    retry: false
  });

  const getCustomMapMarkers = useCallback(async () => {
    if (!userGeolocation) return;

    setIsLoadingMarkers(true);

    try {
      const places = fetchMarkersQuery.data
      setCustomPlaces(places || []);
      // setPathMarkers(markers?.)
    } catch (error) {
      console.error('Error fetching map markers:', error);
      setCustomPlaces([]);
    } finally {
      setIsLoadingMarkers(false);
    }
  }, []);

  const handleMarkerClick = (place: CustomPlace) => {
    setCurrentActivity(place);
    setDrawerOpen(true);
  };

  const handlePlanUpdate = useCallback((updatedActivities: Activity[]) => {
    if (selectedPlan?.id) {
      setActivities(updatedActivities);
    }
  }, [selectedPlan?.id, setActivities]);

  const handleStartNavigation = useCallback(async (plan: Plan) => {
    try {
      if (!userGeolocation) {
        throw new Error('Cannot start navigation without current position');
      }

      const firstActivity = plan.activities[0];
      const response = await mapsClient.directions({
        origin: `${userGeolocation.lat},${userGeolocation.lng}`,
        destination: `${firstActivity.lat},${firstActivity.lng}`,
        waypoints: plan.activities.slice(1).map((activity: Activity) => ({
          location: `${activity.lat},${activity.lng}`
        }))
      });

      if (!response.routes.length) {
        throw new Error('No route found');
      }

      setNavigationState({
        isActive: true,
        currentPath: response.routes[0].overview_path,
        currentActivityIndex: 0,
        remainingActivities: plan.activities
      });
    } catch (error) {
      console.error('Failed to start navigation:', error);
    }
  }, [userGeolocation, mapsClient]);

  const handleActivityReached = useCallback(() => {
    setNavigationState(prev => {
      const newRemainingActivities = prev.remainingActivities.slice(1);

      if (!newRemainingActivities.length) {
        return {
          isActive: false,
          currentPath: [],
          currentActivityIndex: 0,
          remainingActivities: []
        };
      }

      return {
        ...prev,
        currentActivityIndex: prev.currentActivityIndex + 1,
        remainingActivities: newRemainingActivities
      };
    });
  }, []);


  useEffect(() => {
    getCustomMapMarkers()
  }, [])

  // Update paths when position changes
  // useEffect(() => {
  //   if (position && activities.length > 0) {
  //     const updatedPaths = activities.map(activity => ({
  //       id: `path-${activity.place_id}`,
  //       points: [
  //         { lat: position.lat, lng: position.lng, place_id: 'user', name: 'Your Location', isUser: true },
  //         activity
  //       ]
  //     }));
  //     setPathMarkers(updatedPaths);
  //   }
  // }, [position, activities]);

  const UserLocationMarker = useCallback(() => {
    return (
      <>
        <Marker
          position={userGeolocation}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: '#00ffff',
            fillOpacity: 1,
            strokeColor: '#ffffff',
            strokeWeight: 2,
          }}
          zIndex={1000}
        />
        <Circle
          center={userGeolocation}
          radius={50}
          options={{
            fillColor: '#00ffff',
            fillOpacity: 0.1,
            strokeColor: '#00ffff',
            strokeOpacity: 0.2,
            strokeWeight: 1,
            zIndex: 999,
          }}
        />
      </>
    );
  }, [userGeolocation]);

  useEffect(() => {
    if (userGeolocation) {
      setCenter(userGeolocation);
      if (mapRef.current) {
        mapRef.current.panTo(userGeolocation);
      }
    }
  }, [userGeolocation]);

  if (!isLoaded) {
    return (
      <div className="loading-overlay">
        <CircularProgress />
      </div>
    );
  }

  if (loadError) {
    return <Alert severity="error">Error loading maps</Alert>;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={DEFAULT_ZOOM}
        options={{
          clickableIcons: false,
          disableDefaultUI: true,
          fullscreenControl: false,
          gestureHandling: 'greedy',
          mapTypeControl: false,
          streetViewControl: false,
          styles: MAP_STYLES,
          zoomControl: false,
        }}
        onLoad={onMapLoad}
      >
        {userGeolocation && <UserLocationMarker />}

        {fetchMarkersQuery.data && fetchMarkersQuery.data.map((place) => (
          <MemoizedMarker
            key={place.place_id}
            place={place}
            onClick={() => handleMarkerClick(place)}
          />
        ))}

        {activities.map((activity, idx) => (
          <>
            <MapMarker
              key={activity.place_id}
              isUser={false}
              customPlace={activity}
              onClick={() => handleMarkerClick(activity)}
            />
            <Path
              key={`path-${idx}`}
              originCoords={ idx === 0 ? userGeolocation : activities [idx - 1] }
              destinationCoords={activity || activities[0]}
              isActive={true} // TODO: Fix this
              // isActive={selectedPlan?.activities.find() === act.points[1].place_id}
            />
          </>
        ))}

        {navigationState.isActive && (
          <>
            <Polyline
              path={navigationState.currentPath}
              options={{
                strokeColor: '#00FFFF',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                geodesic: true
              }}
            />
            {navigationState.remainingActivities.map((activity, index) => (
              <Marker
                key={activity.place_id}
                position={{ lat: activity.lat, lng: activity.lng }}
                label={{
                  text: (index + 1).toString(),
                  color: '#FFFFFF',
                  fontWeight: 'bold'
                }}
              />
            ))}
          </>
        )}
      </GoogleMap>
      <FilterPanel
        filters={{
          activityType: filters.activityTypes,
          priceRange: filters.priceRange,
          ambiance: filters.ambiance,
          distance: filters.distance
        }}
        onChange={(newFilters) => {
          setFilters({
            activityTypes: newFilters.activityType,
            priceRange: newFilters.priceRange,
            ambiance: newFilters.ambiance,
            distance: newFilters.distance
          });
        }}
        isOpen={isFilterOpen}
        onToggle={() => setIsFilterOpen(!isFilterOpen)}
      />

      <ActivityDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        selectedActivity={currentActivity as Activity}
      />

      <PlanStepper />

      {/* {navigationState.isActive && (
        <RealTimeNavigation
          currentPlan={{
            plan
            currentActivityIndex: navigationState.currentActivityIndex
          }}
          onRouteDeviation={handleRouteDeviation}
          onActivityReached={handleActivityReached}
        />
      )} */}
    </>
  );
};

export default Map;
