export interface NavigationStep {
  instruction: string;
  distance: string;
  duration: string;
}

export interface NavigationError {
  message: string;
  severity: 'error' | 'warning' | 'info';
}

export interface PathSymbol {
  icon: {
    path: google.maps.SymbolPath;
    scale: number;
    fillColor: string;
    fillOpacity: number;
    strokeColor?: string;
    strokeWeight: number;
  };
  offset: string;
  repeat?: string;
}

export interface FilterOptions {
  activityTypes: string[];
  priceRange: [number, number];
  ambiance: string[];
  distance: number;
}

export interface NavigationState {
  isActive: boolean;
  currentPath: google.maps.LatLng[];
  currentStopIndex: number;
  remainingStops: CustomMapMarker[];
}

export interface Stop {
  id: string;
  lat: number;
  lng: number;
  name: string;
}

export interface Plan {
  stops: Stop[];
  id: string;
}

export enum ActivityEnum {
  Restaurant = 'restaurant',
  Bar = 'bar',
  Cafe = 'cafe',
  Attraction = 'attraction',
  Shopping = 'shopping',
  Entertainment = 'entertainment'
}

export enum AmbianceEnum {
  Casual = 'casual',
  Upscale = 'upscale',
  Romantic = 'romantic',
  FamilyFriendly = 'family-friendly',
  Trendy = 'trendy',
  Quiet = 'quiet'
}

export interface CustomMapMarker {
  id: string;
  name: string;
  lat: number;
  lng: number;
  vicinity?: string;
  user_ratings_total?: number;
  price_level?: number;
  photos?: {
    height: number;
    width: number;
    html_attributions: string[];
    photo_reference: string;
  }[];
  opening_hours?: {
    weekday_text: string[];
    open_now?: boolean;
  };
  reviews?: {
    author_name: string;
    rating: number;
    text: string;
  }[];
  rating?: number;
  types?: string[];
  place_id?: string;
  description?: string;
  travel_time?: number;
  estimated_cost?: number;
  duration?: number;
  cost?: number;
  isUser?: boolean;
}

export interface Filters {
  activityTypes: ActivityEnum[];
  priceRange: [number, number];
  ambiance: AmbianceEnum[];
}

export interface Point {
  lat: number;
  lng: number;
}

export interface PathVenue {
  id: string;
  name: string;
  type: string;
  rating: number;
  price: string;
  position: Point;
}
