import React from 'react';
import {
  BrowserRouter,
  useNavigationType,
  useLocation,
  BrowserRouterProps
} from 'react-router-dom';
import { useEffect } from 'react';

/**
 * A custom React hook that logs route changes and navigation actions.
 * This hook uses React Router's useLocation and useNavigationType hooks
 * to track and log navigation information.
 */
const useRouteLogger = () => {
  const location = useLocation();
  const action = useNavigationType();

  useEffect(() => {
    console.debug(`The last navigation action was ${action}`, location);
  }, [location, action]);
};

const RouteDebugger: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useRouteLogger();
  return <>{children}</>;
};

/**
 * A React functional component that wraps BrowserRouter with additional debugging capabilities.
 */
const BrowserRouterWithEffects: React.FC<BrowserRouterProps> = ({ children, ...props }) => {
  return (
    <BrowserRouter {...props}>
      <RouteDebugger>
        {children}
      </RouteDebugger>
    </BrowserRouter>
  );
};

export default BrowserRouterWithEffects;
