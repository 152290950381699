interface DirectionsRequest {
  origin: string;
  destination: string;
  waypoints?: { location: string }[];
  mode?: google.maps.TravelMode;
}

export interface RouteStep {
  instructions: string;
  distance: string;
  duration: string;
  path: google.maps.LatLng[];
}

export interface Route {
  overview_path: google.maps.LatLng[];
  legs: {
    steps: RouteStep[];
    distance: string;
    duration: string;
  }[];
}

export class GoogleMapsClient {
  private directionsService: google.maps.DirectionsService | null = null;
  private retryAttempts = 3;
  private retryDelay = 1000; // 1 second

  initialize() {
    if (!window.google?.maps) {
      throw new Error('Google Maps API not loaded');
    }
    this.directionsService = new google.maps.DirectionsService();
  }

  private async retryOperation<T>(operation: () => Promise<T>): Promise<T> {
    let lastError: Error | null = null;

    for (let attempt = 0; attempt < this.retryAttempts; attempt++) {
      try {
        return await operation();
      } catch (error) {
        lastError = error as Error;
        if (attempt < this.retryAttempts - 1) {
          await new Promise(resolve => setTimeout(resolve, this.retryDelay * Math.pow(2, attempt)));
        }
      }
    }

    throw lastError || new Error('Operation failed after multiple attempts');
  }

  async directions(request: DirectionsRequest): Promise<{ routes: Route[] }> {
    if (!this.directionsService) {
      throw new Error('GoogleMapsClient not initialized');
    }

    try {
      const response = await this.retryOperation(() =>
        new Promise<google.maps.DirectionsResult>((resolve, reject) => {
          this.directionsService!.route({
            origin: request.origin,
            destination: request.destination,
            waypoints: request.waypoints,
            travelMode: request.mode || google.maps.TravelMode.WALKING,
            optimizeWaypoints: true
          }, (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
            if (status === google.maps.DirectionsStatus.OK && result) {
              resolve(result);
            } else {
              reject(new Error(`Directions request failed: ${status}`));
            }
          });
        })
      );

      if (!response.routes.length) {
        throw new Error('No routes found');
      }

      return {
        routes: response.routes.map((route: google.maps.DirectionsRoute) => ({
          overview_path: route.overview_path || [],
          legs: route.legs.map((leg: google.maps.DirectionsLeg) => ({
            steps: leg.steps.map((step: google.maps.DirectionsStep) => ({
              instructions: step.instructions || 'Continue on route',
              distance: step.distance?.text || 'Unknown distance',
              duration: step.duration?.text || 'Unknown duration',
              path: step.path || []
            })),
            distance: leg.distance?.text || 'Unknown distance',
            duration: leg.duration?.text || 'Unknown duration'
          }))
        }))
      };
    } catch (error) {
      console.error('Error getting directions:', error);
      throw new Error(
        error instanceof Error
          ? `Failed to get directions: ${error.message}`
          : 'Failed to get directions'
      );
    }
  }

  async checkConnection(): Promise<boolean> {
    try {
      await this.directions({
        origin: '40.7128,-74.0060',
        destination: '40.7129,-74.0061'
      });
      return true;
    } catch {
      return false;
    }
  }
}
