import React, { useCallback, useRef, useState, useMemo, useEffect, memo } from 'react';
import { APIEndpoint, APIClient } from '../../lib/api_client';
import { GoogleMap, useLoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';
import { Alert, CircularProgress } from '@mui/material';
import { usePlan } from '../../hooks/plan_provider';
import { useQuery } from '@tanstack/react-query';
import { CustomMapMarker, FilterOptions, NavigationState, Plan, Stop } from './types';
import PathLine from './PathLine';
import { FilterPanel } from './FilterPanel';
import ActivityDrawer from '../ActivityDrawer/ActivityDrawer';
import RealTimeNavigation from './RealTimeNavigation';
import { GoogleMapsClient } from '../../lib/maps_client';
import { useGeolocation } from '../../hooks/geolocation_context';
import Cookies from 'js-cookie';
import MapMarker from './MapMarker';
import './Map.scss';

export const DEFAULT_LOCATION: google.maps.LatLngLiteral = { lat: 40.7128, lng: -74.0060, };
const DEFAULT_ZOOM = 13;
const GOOGLE_MAPS_LIBRARIES: ("places")[] = ['places'];
const MAP_STYLES: google.maps.MapTypeStyle[] = [
  { elementType: "geometry", stylers: [{ color: "#011B2D" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#011B2D" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  { featureType: "administrative.locality", elementType: "labels.text.fill", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road", elementType: "geometry", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road", elementType: "geometry.stroke", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road", elementType: "labels.text.fill", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#00FFFF" }] },
  { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#00FFFF" }] },
  { featureType: "water", elementType: "geometry", stylers: [{ color: "#001B2D" }] },
  { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#00FFFF" }] },
  { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] }
];


const getIconUrl = (marker: CustomMapMarker): string => {
  // We could use types, but they need to fit within those types allowable in this big list:
  // https://developers.google.com/maps/documentation/places/web-service/supported_types
  // Note that types are not mutually exclusive. A place may have both food and bar types.
  //
  // We'll do price range for now: Free, Inexpensive, Moderate, Expensive, Very Expensive
  // let logo_path = `/logo-red.png`;
  // let colors = ['green', 'yellow', 'magenta', 'purple', 'red']
  // if (marker.price_level) logo_path = `/logo-${colors[marker.price_level]}.png`;

  return '/marker.png'
}

const MemoizedMarker = memo(({ marker, onClick }: { marker: CustomMapMarker; onClick: () => void }) => (
  <Marker
    key={marker.place_id}
    position={{ lat: marker.lat, lng: marker.lng }}
    onClick={onClick}
    title={marker.name}
    icon={{ url: getIconUrl(marker), scaledSize: new google.maps.Size(24, 24) }}
  />
));

const Map: React.FC = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const { activities, setActivities, selectedPlan, setSelectedPlan } = usePlan();
  const mapRef = useRef<google.maps.Map | null>(null);
  const [center, setCenter] = useState(DEFAULT_LOCATION);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mapMarkers, setCustomMapMarkers] = useState<CustomMapMarker[]>([]);
  const [pathMakers, setPathMarkers] = useState<any>([]);
  const [isLoadingMarkers, setIsLoadingMarkers] = useState(false);
  const [markerInfo, setMarkerInfo] = useState<CustomMapMarker | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<FilterOptions>({
    activityTypes: [],
    priceRange: [0, 4],
    ambiance: [],
    distance: 5000
  });
  const [navigationState, setNavigationState] = useState<NavigationState>({
    isActive: false,
    currentPath: [],
    currentStopIndex: 0,
    remainingStops: []
  });
  const { position, requestLocation } = useGeolocation();
  const mapsClient = useMemo(() => new GoogleMapsClient(), []);

  const activityMapping = useMemo(() => ({
    food: ['restaurant', 'bar', 'cafe'],
    entertainment: ['entertainment'],
    culture: ['attraction'],
    shopping: ['shopping'],
    outdoors: ['attraction']
  }), []);

  useEffect(() => {
    if (isLoaded) {
      try {
        mapsClient.initialize();
      } catch (error) {
        console.error('Failed to initialize Google Maps client:', error);
      }
    }
  }, [isLoaded, mapsClient]);

  useEffect(() => {
    if (position) {
      const userLocation = {
        lat: position.lat,
        lng: position.lng,
      };
      setCenter(userLocation);
      if (mapRef.current) {
        mapRef.current.panTo(userLocation);
        if (!sessionStorage.getItem('initial_location_set')) {
          mapRef.current.setZoom(15);
          sessionStorage.setItem('initial_location_set', 'true');
        }
      }

      if (activities.length > 0) {
        const newPaths = activities.map(activity => ({
          id: `path-${activity.id}`,
          points: [
            { lat: userLocation.lat, lng: userLocation.lng, id: 'user', name: 'Your Location', isUser: true },
            activity
          ]
        }));
        setPathMarkers(newPaths);
      }
    }
  }, [position, activities]);

  const handleFilterSelect = useCallback((filter: string) => {
    setFilters(prev => ({
      ...prev,
      activityTypes: activityMapping[filter as keyof typeof activityMapping] || []
    }));
  }, [activityMapping]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    // Only add event listeners if map and div exist
    if (map && map.getDiv()) {
      const mapDiv = map.getDiv();
      mapDiv.addEventListener('touchstart', () => { }, { passive: true });
      mapDiv.addEventListener('touchmove', () => { }, { passive: true });
    }
  }, []);

  const paths = useMemo(() => {
    // if (!position || !activities.length) return [];
    return activities.map(activity => ({
      id: `path-${activity.id}`,
      points: [
        { lat: center.lat, lng: center.lng, id: 'user', name: 'Your Location', isUser: true },
        activity
      ]
    }));
  }, [mapMarkers, center]);

  const fetchMarkers = async (userLocation: google.maps.LatLngLiteral | null): Promise<CustomMapMarker[]> => {
    const response = await APIClient.get(`${APIEndpoint}/markers/`, {
      headers: { Accept: 'application/json' },
      params: { lat: userLocation?.lat || 40.719632, lng: userLocation?.lng || -73.984810 }
    });
    return response.data;
  };

  const fetchMarkersQuery = useQuery({
    queryKey: ['fetchMarkers'],
    queryFn: () => fetchMarkers(position),
    staleTime: 2 * 60 * 60 * 1000, // 2 hours
    enabled: !!Cookies.get("mindara_at"),
    retry: false
  });

  const getCustomMapMarkers = useCallback(async () => {
    if (!position) return;

    setIsLoadingMarkers(true);

    try {
      if (!fetchMarkersQuery.isLoading) {
        const markers = fetchMarkersQuery.data
        setCustomMapMarkers(markers || []);
        // setPathMarkers(markers?.)
      }
    } catch (error) {
      console.error('Error fetching map markers:', error);
      setCustomMapMarkers([]);
    } finally {
      setIsLoadingMarkers(false);
    }
  }, [fetchMarkersQuery]);

  const handleMarkerClick = useCallback((marker: CustomMapMarker) => {
    setMarkerInfo(marker);
    setDrawerOpen(true);
  }, [mapMarkers]);

  const handlePlanUpdate = useCallback((updatedStops: CustomMapMarker[]) => {
    if (selectedPlan?.id) {
      setActivities(updatedStops);
    }
  }, [selectedPlan?.id, setActivities]);

  const handleStartNavigation = useCallback(async (plan: Plan) => {
    try {
      if (!position) {
        throw new Error('Cannot start navigation without current position');
      }

      const firstStop = plan.stops[0];
      const response = await mapsClient.directions({
        origin: `${position.lat},${position.lng}`,
        destination: `${firstStop.lat},${firstStop.lng}`,
        waypoints: plan.stops.slice(1).map((stop: Stop) => ({
          location: `${stop.lat},${stop.lng}`
        }))
      });

      if (!response.routes.length) {
        throw new Error('No route found');
      }

      setNavigationState({
        isActive: true,
        currentPath: response.routes[0].overview_path,
        currentStopIndex: 0,
        remainingStops: plan.stops
      });
    } catch (error) {
      console.error('Failed to start navigation:', error);
    }
  }, [position, mapsClient]);

  const handleRouteDeviation = useCallback(async () => {
    try {
      if (!position || !navigationState.remainingStops.length) {
        return;
      }

      const nextStop = navigationState.remainingStops[0];
      const response = await mapsClient.directions({
        origin: `${position.lat},${position.lng}`,
        destination: `${nextStop.lat},${nextStop.lng}`,
        waypoints: navigationState.remainingStops.slice(1).map(stop => ({
          location: `${stop.lat},${stop.lng}`
        }))
      });

      if (!response.routes.length) {
        throw new Error('No alternative route found');
      }

      setNavigationState(prev => ({
        ...prev,
        currentPath: response.routes[0].overview_path
      }));
    } catch (error) {
      console.error('Failed to recalculate route:', error);
    }
  }, [position, navigationState.remainingStops, mapsClient]);

  const handleStopReached = useCallback(() => {
    setNavigationState(prev => {
      const newRemainingStops = prev.remainingStops.slice(1);

      if (!newRemainingStops.length) {
        return {
          isActive: false,
          currentPath: [],
          currentStopIndex: 0,
          remainingStops: []
        };
      }

      return {
        ...prev,
        currentStopIndex: prev.currentStopIndex + 1,
        remainingStops: newRemainingStops
      };
    });
  }, []);

  useEffect(() => {
    const hasInteracted = sessionStorage.getItem('map_interaction');
    if (hasInteracted) {
      requestLocation();
    }
  }, [requestLocation]);

  useEffect(() => {
    getCustomMapMarkers()
  }, [position])

  const handleMapClick = useCallback(() => {
    sessionStorage.setItem('map_interaction', 'true');
    if (!position) {
      requestLocation();
    }
  }, [position, requestLocation]);

  useEffect(() => {
    if (position && activities.length > 0) {
      const updatedPaths = activities.map(activity => ({
        id: `path-${activity.id}`,
        points: [
          { lat: position.lat, lng: position.lng, id: 'user', name: 'Your Location', isUser: true },
          activity
        ]
      }));
      setPathMarkers(updatedPaths);
    }
  }, [position, activities]);

  const UserLocationMarker = useCallback(() => {
    if (!position) return null;

    return (
      <>
        <Marker
          position={{ lat: position.lat, lng: position.lng }}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: '#00ffff',
            fillOpacity: 1,
            strokeColor: '#ffffff',
            strokeWeight: 2,
          }}
          zIndex={1000}
        />
        <Circle
          center={{ lat: position.lat, lng: position.lng }}
          radius={50}
          options={{
            fillColor: '#00ffff',
            fillOpacity: 0.1,
            strokeColor: '#00ffff',
            strokeOpacity: 0.2,
            strokeWeight: 1,
            zIndex: 999,
          }}
        />
      </>
    );
  }, [position]);

  useEffect(() => {
    if (position) {
      setCenter(position);
      if (mapRef.current) {
        mapRef.current.panTo(position);
      }
    }
  }, [position]);

  useEffect(() => {
    const hasInteracted = sessionStorage.getItem('map_interaction');
    if (!position && (hasInteracted || process.env.NODE_ENV === 'development')) {
      requestLocation();
    }
  }, [position, requestLocation]);

  if (!isLoaded) {
    return (
      <div className="loading-overlay">
        <CircularProgress />
      </div>
    );
  }

  if (loadError) {
    return <Alert severity="error">Error loading maps</Alert>;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={DEFAULT_ZOOM}
        options={{
          clickableIcons: false,
          disableDefaultUI: true,
          fullscreenControl: false,
          gestureHandling: 'greedy',
          mapTypeControl: false,
          streetViewControl: false,
          styles: MAP_STYLES,
          zoomControl: false,
        }}
        onLoad={onMapLoad}
        onClick={handleMapClick}
      >
        {position && <UserLocationMarker />}

        {mapMarkers.map((marker) => (
          <MemoizedMarker
            key={marker.place_id}
            marker={marker}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}

        {activities.map((marker) => (
          <MapMarker
            key={marker.place_id}
            marker={marker}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}

        {paths.map((path) => (
          <PathLine
            key={path.id}
            path={path.points}
            isActive={selectedPlan?.id === path.points[1].id}
          />
        ))}

        {navigationState.isActive && (
          <>
            <Polyline
              path={navigationState.currentPath}
              options={{
                strokeColor: '#00FFFF',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                geodesic: true
              }}
            />
            {navigationState.remainingStops.map((stop, index) => (
              <Marker
                key={stop.id}
                position={{ lat: stop.lat, lng: stop.lng }}
                label={{
                  text: (index + 1).toString(),
                  color: '#FFFFFF',
                  fontWeight: 'bold'
                }}
              />
            ))}
          </>
        )}
      </GoogleMap>
      <FilterPanel
        filters={{
          activityType: filters.activityTypes,
          priceRange: filters.priceRange,
          ambiance: filters.ambiance,
          distance: filters.distance
        }}
        onChange={(newFilters) => {
          setFilters({
            activityTypes: newFilters.activityType,
            priceRange: newFilters.priceRange,
            ambiance: newFilters.ambiance,
            distance: newFilters.distance
          });
        }}
        isOpen={isFilterOpen}
        onToggle={() => setIsFilterOpen(!isFilterOpen)}
      />

      <ActivityDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        markerInfo={markerInfo}
      />

      {navigationState.isActive && (
        <RealTimeNavigation
          currentPlan={{
            stops: navigationState.remainingStops,
            currentStopIndex: navigationState.currentStopIndex
          }}
          onRouteDeviation={handleRouteDeviation}
          onStopReached={handleStopReached}
        />
      )}
    </>
  );
};

export default Map;
