import React, { useState, useEffect } from 'react';
import { NullLatLngLiteral } from '../types/interfaces';

export const DEFAULT_LOCATION: google.maps.LatLngLiteral = { lat: 40.7128, lng: -74.0060, };

export interface GeolocationContextValue {
  userGeolocation: google.maps.LatLngLiteral;
  error: string | null;
}

export const GeolocationContext = React.createContext<GeolocationContextValue>({
  userGeolocation: DEFAULT_LOCATION,
  error: null,
});

interface GeolocationProviderProps {
  children: React.ReactNode;
}

export const GeolocationProvider: React.FC<GeolocationProviderProps> = ({ children }) => {
  const [userGeolocation, setUserGeolocation] = useState<google.maps.LatLngLiteral>(DEFAULT_LOCATION);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      setError('Geolocation is not supported by this browser.');
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setUserGeolocation({ lat: position.coords.latitude, lng: position.coords.longitude });
        setError(null); // Clear any previous errors on successful update
      },
      (err) => {
        setError(err.message);
      },
      {
        enableHighAccuracy: true, // (Optional) may consume more battery
        timeout: 10000,           // (Optional) throw error if no update within 10s
        maximumAge: 0,            // (Optional) do not accept cached positions
      }
    );

    // Clean up watcher on unmount
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const value: GeolocationContextValue = {
    userGeolocation,
    error,
  };

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
};
