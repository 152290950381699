import React, { useEffect, useState } from 'react';
import './PlanStepper.scss';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Collapse,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { usePlan } from '../../hooks/plan_provider';
import { motion } from 'motion/react';
import { useQueryClient } from '@tanstack/react-query';
import { Plan } from 'src/types/interfaces';

/**
 * Renders the Itinerary component, displaying a list of activities in a plan
 * and providing functionality to manage and save the plan.
 */
const PlanStepper: React.FC = () => {
  const queryClient = useQueryClient();
  const { selectedPlan, activities, setActivities, savePlan, isAtMaxCapacity } = usePlan();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
  const [planName, setPlanName] = useState('');
  const [saveError, setSaveError] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const removeActivity = (place_id: string) => {
    setActivities(activities.filter(activity => activity.place_id !== place_id));
  };

  const handleSave = async () => {
    if (!planName.trim()) {
      setSaveError('Please enter a plan name');
      return;
    }

    try {
      await savePlan({
        name: planName,
        activities: activities,
        is_public: false
      });
      await queryClient.invalidateQueries({ queryKey: ['userPlans'] });
      setSaveDialogOpen(false);
      setPlanName('');
      setSaveError('');
    } catch (error) {
      console.error('Failed to save plan:', error);
      setSaveError('Failed to save plan. Please try again.');
    }
  };

  if (!selectedPlan) return null;

  return (
    <>
      <Paper className="itinerary-container" elevation={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">
            Your Plan {isAtMaxCapacity && <span className="max-capacity-indicator">(Full)</span>}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {!isMobile && (
              <IconButton size="small" onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            )}
            {selectedPlan.activities.length > 0 ? (
              <IconButton
                size="small"
                onClick={() => setSaveDialogOpen(true)}
                color="primary"
              >
                <SaveIcon />
              </IconButton>
            ) : <>Something wrong.</>}
          </Box>
        </Box>

        {isAtMaxCapacity && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Maximum of 3 places reached
          </Alert>
        )}

        <Collapse in={!isCollapsed}>
          {activities.map((activity, index) => (
            <motion.div
              key={activity.place_id}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className="itinerary-item"
            >
              <Box>
                <Typography variant="body1">
                  {index + 1}. {activity.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" className="itinerary-address">
                  {activity.vicinity}
                </Typography>
              </Box>
              <IconButton
                size="small"
                onClick={() => removeActivity(activity.place_id)}
                className="remove-button"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </motion.div>
          ))}
        </Collapse>
      </Paper>

      {isSaveDialogOpen && (
        <Dialog open={isSaveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
          <DialogTitle>Save Your Plan</DialogTitle>
          <DialogContent>
            {saveError && <Alert severity="error">{saveError}</Alert>}
            <TextField
              autoFocus
              margin="dense"
              label="Plan Name"
              type="text"
              fullWidth
              variant="standard"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PlanStepper;