import { FC, useState, useCallback } from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import { APIClient, APIEndpoint } from '../../lib/api_client';
import { useResyConnection } from '../../hooks/useResyConnection';
import resyLogo from '../../assets/resy-logo.png';
import uberLogo from '../../assets/uber-logo.png';
import IntegrationModal from './ResyModal';
import ResyModal from './ResyModal';
import './Integrations.scss';

export interface IntegrationsProps { }
export interface IntegrationModalProps {
  open: boolean;
  isConnected: boolean;
  onClose: () => void;
}
export interface IntegrationProps {
  name: string;
  logo: string;
  isConnected: boolean;
  ModalComponent: FC<IntegrationModalProps>;
}

/**
 * Renders the Integrations component, displaying a list of integration options.
 */
const Integrations: FC<IntegrationsProps> = () => {
  const { data: isResyConnected = false } = useResyConnection();

  const integrations = [
    { name: 'Resy', logo: resyLogo, isConnected: isResyConnected, ModalComponent: ResyModal },
    { name: 'Uber', logo: uberLogo, isConnected: true, ModalComponent: ResyModal }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 8, pb: 7 }}>
        <Box sx={{ p: 3 }} data-testid="Integrations">
          <Typography variant="h4" gutterBottom>Integrations</Typography>
          <Grid container spacing={2}>
            {integrations.map((integration) => (
              <Grid item xs={12} key={integration.name}>
                <Integration {...integration} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

/**
 * A functional component that renders an integration card with a logo, name, and connection status.
 */
const Integration: FC<IntegrationProps> = ({ name, logo, isConnected, ModalComponent }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Paper elevation={3} sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt={`${name} logo`} style={{ width: 40, height: 40, marginRight: 16 }} />
        <Typography variant="h6">{name}</Typography>
      </Box>
      <Button onClick={() => setModalOpen(true)} color={isConnected ? 'secondary' : 'primary'} variant="contained">
        {isConnected ? 'Disconnect' : 'Connect'}
      </Button>
      <ModalComponent open={modalOpen} isConnected={isConnected} onClose={() => setModalOpen(false)} />
    </Paper>
  );
};

export default Integrations;
