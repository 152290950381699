import React, { createContext, useContext, useState, useCallback } from 'react';
import { CustomMapMarker } from '../components/Map/types';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { APIClient } from '../lib/api_client';

export interface Plan {
  id?: string;
  name: string;
  activities: CustomMapMarker[];
  stops: CustomMapMarker[];
  created_at?: string;
  updated_at?: string;
  is_public?: boolean;
}

interface PlanContextType {
  activities: CustomMapMarker[];
  setActivities: (activities: CustomMapMarker[]) => void;
  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan | null) => void;
  userPlans: Plan[];
  isLoading: boolean;
  savePlan: (plan: Plan) => Promise<void>;
  loadPlanForEdit: (planId: string) => Promise<void>;
  deletePlan: (planId: string) => Promise<void>;
  bookResyReservation: (venueId: string, time: string, partySize: number) => Promise<void>;
  findResyVenue: (query: string) => Promise<any>;
  isAtMaxCapacity: boolean;
}

const PlanContext = createContext<PlanContextType | undefined>(undefined);

const fetchUserPlans = async (): Promise<Plan[]> => {
  const response = await APIClient.get('/plans/');
  return response.data.results;
};

const savePlan = async (plan: Plan): Promise<Plan> => {
  if (plan.id) {
    const response = await APIClient.patch(`/plans/${plan.id}/`, plan);
    return response.data;
  } else {
    const response = await APIClient.post('/plans/', plan);
    return response.data;
  }
};

export const PlanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [activities, setActivities] = useState<CustomMapMarker[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [isAtMaxCapacity, setIsAtMaxCapacity] = useState(false);

  const { data: userPlans = [], isLoading } = useQuery<Plan[]>({
    queryKey: ['userPlans'],
    queryFn: fetchUserPlans
  });

  const { mutateAsync: savePlanMutation } = useMutation({
    mutationFn: savePlan,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userPlans'] });
    },
    onError: (error) => {
      console.error('Failed to save plan:', error);
    }
  });

  const handleSavePlan = useCallback(async (plan: Plan) => {
    try {
      await savePlanMutation(plan);
    } catch (error) {
      console.error('Error saving plan:', error);
    }
  }, [savePlanMutation]);

  const loadPlanForEdit = useCallback(async (planId: string) => {
    try {
      const response = await APIClient.get(`/plans/${planId}/`);
      setSelectedPlan(response.data);
      setActivities(response.data.activities || []);
    } catch (error) {
      console.error('Error loading plan:', error);
    }
  }, []);

  const deletePlan = useCallback(async (planId: string) => {
    try {
      await APIClient.delete(`/plans/${planId}/`);
      queryClient.invalidateQueries({ queryKey: ['userPlans'] });
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  }, [queryClient]);

  const bookResyReservation = useCallback(async (venueId: string, time: string, partySize: number) => {
    try {
      await APIClient.post('/resy/book', { venue_id: venueId, time, party_size: partySize });
    } catch (error) {
      console.error('Error booking reservation:', error);
    }
  }, []);

  const findResyVenue = useCallback(async (query: string) => {
    try {
      const response = await APIClient.get(`/resy/search?query=${encodeURIComponent(query)}`);
      return response.data;
    } catch (error) {
      console.error('Error finding venue:', error);
      return [];
    }
  }, []);

  return (
    <PlanContext.Provider
      value={{
        activities,
        setActivities,
        selectedPlan,
        setSelectedPlan,
        userPlans,
        isLoading,
        savePlan: handleSavePlan,
        loadPlanForEdit,
        deletePlan,
        bookResyReservation,
        findResyVenue,
        isAtMaxCapacity
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};