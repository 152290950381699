import React, { useEffect, useState } from 'react';
import { Box, Button, SwipeableDrawer, Typography, CircularProgress } from '@mui/material';
import { CustomMapMarker } from '../Map/types';
import { motion } from 'framer-motion';
import { usePlan } from '../../hooks/plan_provider';
import './ActivityDrawer.scss';

interface ActivityDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  markerInfo: CustomMapMarker | null;
}

/**
 * Generates a URL for a marker image based on the provided marker information.
 */
const getMarkerImageUrl = (markerInfo: CustomMapMarker): string => {
  if (markerInfo.photos) {
    const firstPhoto = markerInfo.photos[0];
    return `https://maps.googleapis.com/maps/api/place/photo?maxheight=${firstPhoto.height || "250"}&maxwidth=${firstPhoto.width}&photo_reference=${firstPhoto.photo_reference}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
  } else {
    return 'https://placehold.co/160x30?text=(No+preview+available)&font=roboto'
  }
}

/**
 * Renders an activity drawer component for displaying and managing activity information.
 */
const ActivityDrawer: React.FC<ActivityDrawerProps> = ({ open, onClose, onOpen, markerInfo }) => {
  const { activities, setActivities, isAtMaxCapacity } = usePlan();
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(true)
  const [actionButtonText, setActionButtonText] = useState("")

  const handlePlanUpdate = () => {
    if (!markerInfo) return;

    const existingActivityIndex = activities.findIndex(({ id }) => id === markerInfo.place_id)
    if (existingActivityIndex !== -1) {
      const newActivities = [...activities]
      newActivities.splice(existingActivityIndex, 1)
      setActivities(newActivities)
    } else {
      const newActivity: CustomMapMarker = {
        id: markerInfo.id,
        name: markerInfo.name,
        lat: markerInfo.lat,
        lng: markerInfo.lng,
        place_id: markerInfo.place_id,
        vicinity: markerInfo.vicinity,
      };
      setActivities([...activities, newActivity]);
    }
    onClose();
  };

  useEffect(() => {
    const existingActivityIndex = activities.findIndex(({ id }) => id === markerInfo?.place_id)
    if (existingActivityIndex !== -1) {
      setActionButtonDisabled(false)
      setActionButtonText("Remove from plan")

    } else {
      if (isAtMaxCapacity) {
        setActionButtonDisabled(true)
        setActionButtonText("Plan is full!")
      } else {
        setActionButtonDisabled(false)
        setActionButtonText("Add to plan")
      }
    }
  }, [activities, markerInfo, isAtMaxCapacity])

  if (!markerInfo) return null;

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      classes={{ paper: 'activity-drawer-paper' }}
      aria-labelledby="activity-drawer-title"
    >
      <motion.div
        initial={{ y: 300 }}
        animate={{ y: 0 }}
        exit={{ y: 300 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          className="activity-drawer-container"
          role="dialog"
          aria-modal="true"
        >
          {markerInfo?.photos && !imageError && (
            <img
              src={getMarkerImageUrl(markerInfo)}
              alt={`${markerInfo.name} location`}
              className="activity-drawer-image"
              onLoad={() => setImageLoading(false)}
              onError={() => {
                setImageLoading(false);
                setImageError(true);
              }}
              style={{ display: imageLoading ? 'none' : 'block' }}
            />
          )}

          {imageLoading && (
            <Box className="image-loading-placeholder">
              <CircularProgress size={24} />
            </Box>
          )}

          <Typography
            variant="h5"
            gutterBottom
            id="activity-drawer-title"
          >
            {markerInfo.name || 'No Info Available'}
          </Typography>

          <Typography variant="body1" color="text.secondary">{markerInfo.vicinity || 'No Address Available'}</Typography>
          {markerInfo.price_level && (
            <Typography variant="body2" color="text.secondary">
              Price Level: {'💰'.repeat(markerInfo.price_level)}
            </Typography>
          )}
          {markerInfo.user_ratings_total && (
            <Typography variant="body2" color="text.secondary">
              Rating: {markerInfo.rating}★ ({markerInfo.user_ratings_total} reviews)
            </Typography>
          )}
          {markerInfo.opening_hours && (
            <div className="opening-hours">
              <Typography variant="subtitle1">
                {markerInfo.opening_hours.open_now ? 'Open Now' : 'Closed Now'}
              </Typography>
              {markerInfo.opening_hours.weekday_text && (
                <>
                  <Typography variant="body2">
                    <strong>Opening Hours:</strong>
                  </Typography>
                  <ul>
                    {markerInfo.opening_hours.weekday_text.map((day: string, index: number) => (
                      <li key={index}>{day}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          )}
          {markerInfo.reviews && markerInfo.reviews.length > 0 && (
            <div className="user-reviews">
              <Typography variant="subtitle1">User Reviews</Typography>
              {markerInfo.reviews.map((review: { author_name: string; rating: number; text: string }, index: number) => (
                <Box key={index} className="review">
                  <Typography variant="body2">
                    <strong>{review.author_name}</strong> - {review.rating}★
                  </Typography>
                  <Typography variant="body2">{review.text}</Typography>
                </Box>
              ))}
            </div>
          )}
          <Box className="activity-drawer-actions">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${markerInfo.lat},${markerInfo.lng}`, '_blank')}
              aria-label={`Get directions to ${markerInfo.name}`}
            >
              Discover
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePlanUpdate}
              className="add-to-plan-button"
              disabled={actionButtonDisabled}
            >
              {actionButtonText}
            </Button>
          </Box>
        </Box>
      </motion.div>
    </SwipeableDrawer>
  );
}

export default ActivityDrawer;
