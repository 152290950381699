import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { Polyline } from '@react-google-maps/api';
import { usePlan } from '../../hooks/plan_provider';
import { decode, LatLngTuple } from '@googlemaps/polyline-codec';
import { CustomPlace } from '../../types/interfaces';
import { GeolocationContext } from 'src/hooks/geolocation_provider';


interface PathProps {
  originCoords: google.maps.LatLngLiteral;
  destinationCoords: google.maps.LatLngLiteral;
  isActive?: boolean;
  routePolyline?: string;
}

interface PathSymbol {
  icon: {
    path: google.maps.SymbolPath;
    scale: number;
    fillColor: string;
    fillOpacity: number;
    strokeColor?: string;
    strokeWeight: number;
  };
  offset: string;
  repeat?: string;
}

const Path: React.FC<PathProps> = ({ originCoords: originCoords, destinationCoords, isActive = false, routePolyline }) => {
  const [pathCoordinates, setPathCoordinates] = useState<google.maps.LatLngLiteral[]>([]);
  const [animationOffset, setAnimationOffset] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [glowIntensity, setGlowIntensity] = useState(0);
  const [dotFadeOpacity, setDotFadeOpacity] = useState(1);
  const [totalPathLength, setTotalPathLength] = useState(0);
  const [segmentLengths, setSegmentLengths] = useState<number[]>([]);
  const [currentSegment, setCurrentSegment] = useState(0);
  const { userPlans } = usePlan();
  const { userGeolocation, error } = useContext(GeolocationContext);


  useEffect(() => {
    const fetchDirectionsForSegments = async () => {
      try {
        const directionsService = new google.maps.DirectionsService();
        console.debug(userPlans);
        let allCoordinates: google.maps.LatLngLiteral[] = [];
        let lengths: number[] = [];
        let totalLength = 0;

        const request: google.maps.DirectionsRequest = {
          origin: originCoords,
          destination: destinationCoords,
          travelMode: google.maps.TravelMode.WALKING,
          optimizeWaypoints: true
        };

        try {
          const result = await directionsService.route(request);
          if (result.routes[0]?.overview_path) {
            const segmentPath = result.routes[0].overview_path.map((latlng) => latlng.toJSON() as google.maps.LatLngLiteral);
            allCoordinates = allCoordinates.concat(segmentPath);

            let segmentLength = 0;
            for (let j = 0; j < segmentPath.length - 1; j++) {
              segmentLength += google.maps.geometry.spherical.computeDistanceBetween(
                segmentPath[j],
                segmentPath[j + 1]
              );
            }
            lengths.push(segmentLength);
            totalLength += segmentLength;
          }
        } catch (error) {
          console.error(`Error fetching directions to ${destinationCoords} from ${originCoords}}:`, error);
          const straightLength = google.maps.geometry.spherical.computeDistanceBetween(originCoords, destinationCoords);
          lengths.push(straightLength);
          totalLength += straightLength;
        }

        setPathCoordinates(allCoordinates);
        setSegmentLengths(lengths);
        setTotalPathLength(totalLength);
      } catch (error) {
        console.error('Error fetching directions:', error);
      }
    };

    fetchDirectionsForSegments();
  }, [originCoords, destinationCoords]);

  useEffect(() => {
    if (isActive && segmentLengths.length > 0) {
      let prevTime = performance.now();
      const animate = (currentTime: number) => {
        const deltaTime = (currentTime - prevTime) / 1000;
        prevTime = currentTime;

        setAnimationOffset(prev => {
          const increment = (20 / 3) * deltaTime; // Complete path in 3 seconds
          const newOffset = (prev + increment) % 100;

          const progress = newOffset / 100;
          let accumulatedLength = 0;
          let currentSegmentIndex = 0;

          for (let i = 0; i < segmentLengths.length; i++) {
            accumulatedLength += segmentLengths[i] / totalPathLength * 100;
            if (progress <= accumulatedLength / 100) {
              currentSegmentIndex = i;
              break;
            }
          }
          setCurrentSegment(currentSegmentIndex);

          if (newOffset < 2) {
            setDotFadeOpacity(newOffset / 2);
          } else if (newOffset > 98) {
            setDotFadeOpacity((100 - newOffset) / 2);
          } else {
            setDotFadeOpacity(1);
          }

          const velocity = increment / deltaTime;
          const targetGlow = Math.min(1, velocity * 0.2);
          setGlowIntensity(prev => prev + (targetGlow - prev) * 0.1);

          return newOffset;
        });
        requestAnimationFrame(animate);
      };
      const animationFrame = requestAnimationFrame(animate);
      return () => cancelAnimationFrame(animationFrame);
    }
  }, [isActive, segmentLengths, totalPathLength]);

  const createPathSymbols = (): PathSymbol[] => {
    if (!isActive) return [];

    const baseGlowIntensity = 0.3 + (glowIntensity * 0.4);
    const symbols: PathSymbol[] = [
      {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 4,
          fillColor: '#FFFFF0',
          fillOpacity: 0.95 * dotFadeOpacity,
          strokeColor: '#FFFFFF',
          strokeWeight: 2,
        },
        offset: `${animationOffset}%`,
      },
      {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8 + (glowIntensity * 4),
          fillColor: '#FFFFF0',
          fillOpacity: baseGlowIntensity * dotFadeOpacity,
          strokeWeight: 0,
        },
        offset: `${animationOffset}%`,
      },
      {
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 12 + (glowIntensity * 6),
          fillColor: '#FFFFF0',
          fillOpacity: baseGlowIntensity * 0.5 * dotFadeOpacity,
          strokeWeight: 0,
        },
        offset: `${animationOffset}%`,
      }
    ];

    const trailLength = 8;
    for (let i = 0; i < trailLength; i++) {
      const fadeOffset = i / trailLength;
      const trailOpacity = (0.3 - (fadeOffset * 0.25)) * (1 + glowIntensity) * dotFadeOpacity;

      symbols.push({
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 3 - (fadeOffset * 2) + (glowIntensity * 2),
          fillColor: '#FFFFF0',
          fillOpacity: trailOpacity,
          strokeWeight: 0,
        },
        offset: `${Math.max(0, animationOffset - (i * 4 * (1 + glowIntensity)))}%`,
      });
    }

    return symbols;
  };

  const pathOptions: google.maps.PolylineOptions = {
    geodesic: true,
    strokeColor: '#FFFFF0',
    strokeOpacity: isHovered ? 0.9 : isActive ? 0.8 : 0.5,
    strokeWeight: isHovered ? 6 : isActive ? 5 : 3,
    icons: createPathSymbols(),
    zIndex: isActive ? 2 : 1,
  };

  return (
    <Polyline
      path={pathCoordinates}
      options={pathOptions}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    />
  );
};

export default React.memo(Path);
